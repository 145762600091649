import { useCallback } from "react";
import { axiosGet } from "../services/axiosService";
import { useDispatch } from "react-redux";
import { userSlice } from "../redux/User";

const FETCH_INTERVAL = 3000;

export default function useProgress() {
  const dispatch = useDispatch();

  const fetchProgress = useCallback(
    async ({ halfProgress = false, onFinish }) => {
      // async function cb() {
      //   try {
      //     const response = await axiosGet("/event/progress");
      //     const { data } = response.data;
      //     dispatch(
      //       userSlice.actions.setTrainData({
      //         pending: data.pending,
      //         progress: halfProgress ? 50 + data.progress / 2 : data.progress,
      //         botId: data.botId,
      //         type: "webpage",
      //       }),
      //     );
      //     if (data.pending) return;
      //     dispatch(userSlice.actions.clearTrainInterval());
      //     if (onFinish) onFinish();
      //     if (data.progress === 100) {
      //       if (window.screen.width > 1279 || data.terminate) {
      //         dispatch(
      //           userSlice.actions.setToastPopUp({
      //             show: true,
      //             toastType: data.terminate ? "error" : "success",
      //             message: data.terminate
      //               ? "Training cancelled. Links trained before cancelling will stay as trained"
      //               : "Successfully trained",
      //           }),
      //         );
      //       } else {
      //         dispatch(
      //           userSlice.actions.setInfoModal({
      //             showModal: true,
      //             modalType: "success",
      //             modalTitle: "Successfully Trained",
      //             modalMessage:
      //               "Bot training has been completed! The bot is now ready.",
      //             showModalButton: false,
      //           }),
      //         );
      //       }
      //     }
      //   } catch (err: any) {
      //     console.log(err?.response?.data?.message ?? err.message);
      //     dispatch(userSlice.actions.clearTrainInterval());
      //   }
      // }
      // await cb();
      // const intervalId = setInterval(cb, FETCH_INTERVAL);
      // dispatch(
      //   userSlice.actions.setTrainInterval({ trainingIntervalId: intervalId }),
      // );
    },
    [],
  );

  return fetchProgress;
}
